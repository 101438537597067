import React, {useState} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Engagement from 'src/views/posts/PostsSummaryView/Engagement';
import Posts from 'src/views/posts/PostsSummaryView/Posts';
import Post from 'src/views/posts/PostsSummaryView/Post';
import Filters from 'src/views/posts/PostsSummaryView/Filters';
import Followers from 'src/views/posts/PostsSummaryView/Followers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const dateCalc = (days) => {
  let d = new Date();
  return d.setDate(d.getDate() - days);
};

const Analytics = () => {

  const classes = useStyles();

  const postPageState = {
    page: 'all',
    posts: true,
    stories: true,
    reels: true,
  };

  const followers = {};

  const [postState, setPostState] = useState(postPageState);
  const [followersState, setfollowersState] = useState(followers);
  const [followersFetchedState, setfollowersFetchedState] = useState(false);

  const getFollowers = (platform) => {
    let tempfollowers = [];
    let tempDates = [];
    let min = 0;
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    fetch(
      baseUrl + '/followerCounts' + '?token=' + localStorage.getItem('token'),
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "userId": localStorage.getItem('user_id'),
        "site": platform
      })
    }
    )
    .then(res => res.json())
    .then(json => {
      if (json.data) {
        for(let i = 0; i < json.data.length; i++){
          tempfollowers.push(parseInt(json.data[i].follower_count));
          tempDates.push(json.data[i].date);
        }
        min = Math.min(...tempfollowers);
      } else {
        console.log("fail");
      }
      setfollowersState({'followers': tempfollowers, 'dates': tempDates, 'min': min});
    });
  }

  const callbackFunction = (stateKey, stateVal) => {
    setPostState({...postState, [stateKey]: stateVal});

    let newState = postState;
    newState[stateKey] = stateVal;

    let postTypes = [];
    if(newState.posts){
      postTypes.push('post');
    }
    if(newState.stories){
      postTypes.push('story');
    }
    if(newState.reels){
      postTypes.push('reel');
    }

    setPgFetchedState(false);

    if(stateKey === 'pages' && stateVal !== 'all'){
      getPosts(stateVal, postTypes);
    } else {
      getPosts('all', postTypes);
    }
  };

  if(followersFetchedState === false){
    getFollowers("Instagram");
    setfollowersFetchedState(true);
  }

  const apiPosts = [];
  const [apiPostsState, setApiPostsState] = useState(apiPosts);

  const pgFetched = false;
  const [pgFetchedState, setPgFetchedState] = useState(pgFetched);

  const getPosts = (pageId, postTypes) => {
    if (pgFetchedState === false) {
      let baseUrl = 'https://api.combanalytics.com';
      if(process.env.NODE_ENV === 'development'){
        baseUrl = 'https://dev.combanalytics.com';
      }
      let body = {"user_id": localStorage.getItem('user_id')}
      if(pageId !== 'all'){
        body.site_page_id = pageId;
        console.log("confirmed")
      }
      body.post_types = postTypes;

      fetch(
        baseUrl + '/facebookPosts' + '?token=' + localStorage.getItem('token'),
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      )
      .then(res => res.json())
      .then(json => {
        if (json) {
          let items = [];
          for (let i = 0; i < json.length; i++) {
            let obj = json[i];

            items.push(obj);

          }
          setApiPostsState(items.reverse());
        } else {
          console.log("fail");
        }
      });
      setPgFetchedState(true);
    }
  }

  let postTypes = [];
  if(postState.posts){
    postTypes.push('post');
  }
  if(postState.stories){
    postTypes.push('story');
  }
  if(postState.reels){
    postTypes.push('reel');
  }

  getPosts(postState.page, postTypes);

  return (
    <Page
      className={classes.root}
      title="Posts"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          alignItems="flex-start"
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Filters stateFilter={callbackFunction} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Engagement state={apiPostsState} followerState={followersState} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Analytics;
