import React, {useState} from 'react';
import {
  Card, CardContent,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Engagement from 'src/views/posts/PostsSummaryView/Engagement';
import Posts from 'src/views/posts/PostsSummaryView/Posts';
import Post from 'src/views/posts/PostsSummaryView/Post';
import Filters from 'src/views/posts/PostsSummaryView/Filters';
import Followers from 'src/views/posts/PostsSummaryView/Followers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const dateCalc = (days) => {
  let d = new Date();
  return d.setDate(d.getDate() - days);
};

const PostsSummary = () => {

  const classes = useStyles();

  const postPageState = {
    platform: 'all',
    reaction: 'all',
    gender: 'all',
    age: 'all',
    time: 'month'
  };

  const followers = {};

  const [postState, setPostState] = useState(postPageState);
  const [followersState, setfollowersState] = useState(followers);

  const getFollowers = (platform) => {
    let tempfollowers = [];
    let tempDates = [];
    let min = 0;
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    fetch(
      baseUrl + '/followerCounts' + '?token=' + localStorage.getItem('token'),
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "userId": localStorage.getItem('user_id'),
        "site": platform
      })
    }
    )
    .then(res => res.json())
    .then(json => {
      if (json.data) {
        console.log(json.data);
        for(let i = 0; i < json.data.length; i++){
          tempfollowers.push(parseInt(json.data[i].follower_count));
          tempDates.push(json.data[i].date);
        }
        min = Math.min(...tempfollowers);
      } else {
        console.log("fail");
      }
      setfollowersState({'followers': tempfollowers, 'dates': tempDates, 'min': min});
    });
  }

  const callbackFunction = (stateKey, stateVal) => {
    setPostState({...postState, [stateKey]: stateVal});
    if(stateKey === 'platform' && stateVal !== 'all'){
      getFollowers(stateVal);
    }
  };

  const apiPosts = [];
  const [apiPostsState, setApiPostsState] = useState(apiPosts);

  const pgFetched = false;
  const [pgFetchedState, setPgFetchedState] = useState(pgFetched);

  if(pgFetchedState === false) {
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    fetch(
      baseUrl + '/instagramPosts' + '?token=' + localStorage.getItem('token'),
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"userId": localStorage.getItem('user_id')})
      }
    )
    .then(res => res.json())
    .then(json => {
      if (json) {
        let items = [];
        for (let i = 0; i < json.length; i++) {
          let obj = json[i];
          let words = [];
          let word_count_json = JSON.parse(obj.word_count_json);

          if(word_count_json !== null) {
            for (let x in word_count_json) {
              words.push({text: x, value: word_count_json[x]});
            }
          }

          localStorage.setItem('post_cloud_' + obj.site_post_id, JSON.stringify(words));

          items.push(<Post
            key={i}
            id={obj.site_post_id}
            icon={obj.site}
            image={obj.img_url}
            date={obj.site_created_at}
            message={obj.message}
            words={words}
            likes={obj.like_count}
            reactions={obj.reaction_count}
            state={postState}
          />);

        }
        setApiPostsState(items);
      } else {
        console.log("fail");
      }
    });
    setPgFetchedState(true);
  }

  return (
    <Page
      className={classes.root}
      title="Posts"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          alignItems="flex-start"
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Filters stateFilter={callbackFunction} />
          </Grid>
          <Grid
            container
            xl={12}
            style={{padding: '12px'}}
            padding={12}
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Grid
                item
                lg={3}
                md={3}
                xl={3}
                xs={3}
              >
                <Posts />
                {apiPostsState.length === 0
                && <Card style={{fontFamily: "arial"}}><CardContent>Hello Facebook Tester - If you are not seeing any Instagram posts from the Instagram page(s) you connected, please make sure it has been <b>at least 60 seconds</b> since you granted permissions to the Comb Analytics Facebook App (the more data you have the longer it will take to ingest).<br /><br />  If you still see nothing please go back to the <a href="account">My Profile Page</a> and grant ALL permissions after clicking the "Connect Facebook And Instagram" button.  Failing to grant ALL permissions (ex "pages_read_engagement") will cause the ingestion of you instagram data to fail. </CardContent></Card>
                }
              </Grid>
            </Grid>
            {apiPostsState}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PostsSummary;
