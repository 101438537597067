import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useNavigate } from 'react-router-dom';
import {GoogleLogin} from '@react-oauth/google';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const responseGoogle = (response) => {
    console.log(response);
    //response.credential
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    fetch(
      baseUrl + '/googleLogin',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({gToken: response.credential})
      }
    ).then(json => {

      if (json.access_token) {
        localStorage.setItem('token', json.access_token);
        localStorage.setItem('user_id', json.user.id);
        localStorage.setItem('user_img', json.user.img_url);
        localStorage.setItem('user_email', json.user.email);
        localStorage.setItem('user_name', json.user.name);
        localStorage.setItem('user_handle', json.user.handle);
        navigate('/app/facebook', {replace: true})
      } else {
        alert("Error: You were not logged in successfully.  Please email support@combanalytics.com");
      }
    })
  };

  const googleError = (error) => {
    alert(error);
  };

  const responseFacebook = (response) => {
    console.log(response);
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    console.log(response.accessToken);
    console.log(response.id);
    fetch(
      baseUrl + '/facebookLogin',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({fbToken: response.accessToken, id: response.id, email: response.email, name: response.name})
      }
    ).then(res => res.json()).then( json => {

      console.log("fail");
      console.log(json);

      if (json.access_token) {
        localStorage.setItem('token', json.access_token);
        localStorage.setItem('user_id', json.user.id);
        localStorage.setItem('user_img', json.user.img_url);
        localStorage.setItem('user_email', json.user.email);
        localStorage.setItem('user_name', json.user.name);
        localStorage.setItem('user_handle', json.user.handle);
        navigate('/app/facebook', {replace: true})
      } else {
        alert("Error: You were not logged in successfully.  Please email support@combanalytics.com");
      }

    });

  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" style={{alignItems: "center", justifyContent: "center"}}>

          <FacebookLogin
            appId="648765019065888"
            autoLoad={false}
            fields="name,email,picture"
            textButton="Sign In With Facebook/Instagram"
            scope="public_profile,pages_read_user_content,pages_show_list,pages_read_engagement,instagram_basic,instagram_manage_comments,instagram_manage_insights,pages_manage_engagement,pages_read_user_content"
            callback={responseFacebook}
            style={{width: "100%", marginBottom: 18}}
            icon={<FacebookIcon />}
            render={renderProps => (
              <button style={{display: "flex", backgroundColor: "#4267B2", color: "white", fontFamily: "Helvetica", lineHeight: "38px", height: "40px", fontSize: 14, width: 300, cursor: "pointer", border: "1px solid #dadce0"}} onClick={renderProps.onClick}><FacebookIcon style={{display: "flex", marginTop: "6px", marginLeft: "8px", marginRight: "20px"}} /> Sign in with Facebook/Instagram</button>
            )}
          />

          {/*<div style={{width: "300px", height: "40px", lineHeight: "38px", fontFamily: "Helvetica", textAlign: "center"}}>OR</div>*/}

          {/*<GoogleLogin onSuccess={responseGoogle} onError={googleError} size={"large"} width={300} />*/}

        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
