import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors, TableRow, TableCell, TableBody
} from '@material-ui/core';
import Post from "./Post";
import {color} from "@material-ui/system";

const useStyles = makeStyles(() => ({
  root: {}
}));

const Engagement = ({ className, state, followerState, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const reach2 = [];
  const [reachState, setReachState] = useState(reach2);

  const likes2 = [];
  const [likesState, setLikesState] = useState(likes2);

  const [followersState, setFollowersState] = useState([]);

  const posts2 = [];
  const [postsState, setPostsState] = useState(posts2);

  const stories2 = [];
  const [storiesState, setStoriesState] = useState(stories2);

  const reels2 = [];
  const [reelsState, setReelsState] = useState(reels2);

  const dates2 = [];
  const [datesState, setDatesState] = useState(dates2);

  let followers = [];
  let posts = [];
  let stories = [];
  let reels = [];
  //let dates = ["07/23/2021", "07/24/2021", "07/25/2021", "07/26/2021", "07/27/2021", "07/28/2021", "07/29/2021"];
  let dates = [];
  let reach = [];
  let likes = []

  const emptyDateGroup = {reach: 0, likes: 0};
  const dateGroups = [emptyDateGroup,emptyDateGroup,emptyDateGroup,emptyDateGroup,emptyDateGroup,emptyDateGroup,emptyDateGroup];
  const [dateGroupState, setDateGroupState] = useState(dateGroups);

  const pgFetched = false;
  const [pgFetchedState, setPgFetchedState] = useState(pgFetched);

  const followerFetched = false;
  const [followerFetchedState, setfollowerFetchedState] = useState(followerFetched);

  if(pgFetchedState === false && state.length > 0) {

    let dateGroup = [];
    let dateGroup2 = [];

    for(let i = 0; i < state.length; i++){
      let tempDate = state[i].site_created_at.split(' ')[0];
      if (tempDate in dateGroup) {
        dateGroup[tempDate].reach += state[i].reach;
        dateGroup[tempDate].likes += state[i].like_count;
      } else {
        dateGroup[tempDate] = {};
        dateGroup[tempDate].reach = state[i].reach;
        dateGroup[tempDate].likes = state[i].like_count;
      }

      if (state[i].post_type === "story") {
        stories.push(state[i]);
      } else if (state[i].post_type === "post") {
        posts.push(state[i]);
      } else if (state[i].post_type === "reel") {
        reels.push(state[i]);
      }

      if(!dates.includes(tempDate)) {
        dates.push(tempDate);
      }

      if(i === state.length - 1){

        for(let n = 0; n < dates.length; n++){
          dateGroup2[n] = dateGroup[dates[n]];
          reach.push(dateGroup[dates[n]].reach);
          likes.push(dateGroup[dates[n]].likes);
        }

        setDateGroupState(dateGroup2);

      }
    }

    setPostsState(posts);
    setStoriesState(stories);
    setReelsState(reels);
    setDatesState(dates);

    setReachState(reach);
    setLikesState(likes);

    setPgFetchedState(true);

  }

  if(pgFetchedState === true && followerFetchedState === false && followerState.followers) {
    for (let i = 0; i < datesState.length - followerState.followers.length; i++) {
      followers.push(0);
    }

    for (let i = 0; i < followerState.followers.length; i++) {
      followers.push(followerState.followers[0])
    }

    setFollowersState(followers);

    setfollowerFetchedState(true);
  }

  const data = {
    datasets: [
      {
        backgroundColor: colors.blue[500],
        data: reachState,
        label: 'Reach'
      },
      {
        backgroundColor: colors.grey[500],
        data: likesState,
        label: 'Likes'
      },
      {
        backgroundColor: colors.orange[500],
        data: followersState,
        label: 'Followers'
      },
    ],
    options: {
      scales: {
        yAxes: [{
          id: 'A',
          type: 'linear',
          position: 'left',
        }]
      }
    },
    labels: datesState
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  let cardHeaderTitle = "POSTS: " + postsState.length + " +0 STORIES: " + storiesState.length + " +0 REELS: " + reelsState.length + " +0 FOLLOWERS: " + followersState[followersState.length - 1] + " +0"

  return (
    <>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title={cardHeaderTitle}
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
    </Card>
  <Card style={{marginTop: "20px"}}>
    <CardContent>
      <Box>
        <table>
          <TableBody>
          <TableRow>
            <TableCell></TableCell>
            {datesState.map((date) => (
              <TableCell>{date}</TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell style={{color: "blue"}}>Reach</TableCell>
            {dateGroupState.map((date) => (
              <TableCell>{date.reach}</TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell style={{color: "grey"}}>Likes</TableCell>
            {dateGroupState.map((date) => (
              <TableCell>{date.likes}</TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell style={{color: "orange"}}>Followers</TableCell>
            {followersState.map((followerCount) => (
              <TableCell>{followerCount}</TableCell>
            ))}
          </TableRow>
          </TableBody>
        </table>
      </Box>
    </CardContent>
  </Card>
      </>
  );
};

Engagement.propTypes = {
  className: PropTypes.string
};

export default Engagement;
