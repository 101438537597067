import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
  makeStyles,
  Divider
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import ReactHighlightWords from "react-highlight-words";
import DeleteButton from './DeleteButton';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, customers, word, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [clickedCellId, setClickedCellId] = useState(0);
  const [showHideDelete, setShowHideDelete] = useState(false);
  const [replyText, setReplyText] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const hideShowReply = (cellId) => {
    setReplyText(null);
    if(cellId === clickedCellId){
      setClickedCellId(0);
    } else {
      setClickedCellId(cellId);
    }
  }

  let highlightWord = "";

  if(typeof word === 'string'){
    highlightWord = word;
  }

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    if(newSelectedCustomerIds.length > 0 ){
      setShowHideDelete(true);
    } else {
      setShowHideDelete(false);
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    console.log(newSelectedCustomerIds);

    if(newSelectedCustomerIds.length > 0 ){
      setShowHideDelete(true);
    } else {
      setShowHideDelete(false);
    }
    console.log(showHideDelete);

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectRow = (event, id) => {

  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const deleteComments = () => {
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    fetch(
      baseUrl + '/facebookDeleteComments' + '?token=' + localStorage.getItem('token'),
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"ids": selectedCustomerIds})
      }
    )
    .then(res => res.json())
    .then(json => {
      if (json) {
        console.log(localStorage.getItem('token'));
        console.log(json);
        window.location.reload(false);
      } else {
        console.log("fail");
      }
    });
  }

  const reply = (id, reply) => {
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    fetch(
      baseUrl + '/facebookPublishComment' + '?token=' + localStorage.getItem('token'),
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"id": id, "reply": replyText})
      }
    )
      .then(res => res.json())
      .then(json => {
        if (json) {
          console.log(localStorage.getItem('token'));
          console.log(json);
          setClickedCellId(0);
          window.location.reload(false);
        } else {
          console.log("fail");
        }
      });
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Reaction
                </TableCell>
                <TableCell>
                  Comment
                </TableCell>
              </TableRow>
            </TableHead>
            <ReactCSSTransitionGroup
              transitionName="fade"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
              transitionAppearTimeout={500}
              transitionAppear={true}
              component="tbody"
            >
              {customers.slice(0, limit).map((customer) => (
                <>
                <TableRow
                  hover
                  key={customer.id}
                  selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                      onChange={(event) => handleSelectOne(event, customer.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Avatar
                        className={classes.avatar}
                        src={customer.avatarUrl}
                      >
                        {customer.site_user ? getInitials(customer.site_user) : ''}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {customer.site_user}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {customer.reaction}
                  </TableCell>
                  <TableCell id={"comment-id-" + customer.id} onClick={() => hideShowReply(customer.id)}>
                    <ReactHighlightWords searchWords={[highlightWord]} autoEscape={true} textToHighlight={customer.message} />
                  </TableCell>
                </TableRow>
                {
                  clickedCellId === customer.id
                  &&
                  <TableRow id={"reply-row-id-" + customer.id} style={{background: "light-grey"}}>
                    <TableCell colspan="8" style={{"text-align": "center"}}>
                      <TextField onChange={e => setReplyText(e.target.value)} fullWidth={true}></TextField>
                      <Button onClick={() => reply(customer.id, replyText)}
                              style={{float: "right", background: "lightblue", marginTop: "16px"}}>Reply</Button>
                    </TableCell>
                  </TableRow>
                }
                </>
              ))}
            </ReactCSSTransitionGroup>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {showHideDelete &&
      <Divider /> &&
      <Box style={{paddingTop: "50px", paddingLeft: "50px", paddingRight: "50px", paddingBottom: "90px"}}>
        <TextField onChange={e => setReplyText(e.target.value)} fullWidth={true}></TextField>
        <Button style={{float: "right", background: "lightblue", marginTop: "16px"}}>Reply To Selected</Button>
        <Button onClick={deleteComments} style={{float: "right", color: "white", background: "red", marginTop: "16px", marginRight: "16px"}}>DELETE SELECTED</Button>
      </Box>
      }
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
