import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button, Checkbox,
  makeStyles, TableCell, TableRow, Typography
} from '@material-ui/core';
import getInitials from "../../../utils/getInitials";
import ReactHighlightWords from "react-highlight-words";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const TableCustomRow = (customer, selectedCustomerIds, handleSelectOne, classes, highlightWord) => {
  return (
    <TableRow
      hover
      key={customer.id}
      selected={selectedCustomerIds.indexOf(customer.id) !== -1}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedCustomerIds.indexOf(customer.id) !== -1}
          onChange={(event) => handleSelectOne(event, customer.id)}
          value="true"
        />
      </TableCell>
      <TableCell>
        <Box
          alignItems="center"
          display="flex"
        >
          <Avatar
            className={classes.avatar}
            src={customer.avatarUrl}
          >
            {customer.site_user ? getInitials(customer.site_user) : ''}
          </Avatar>
          <Typography
            color="textPrimary"
            variant="body1"
          >
            {customer.site_user}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        {customer.reaction}
      </TableCell>
      <TableCell>
        <ReactHighlightWords searchWords={[highlightWord]} autoEscape={true} textToHighlight={customer.message} />
      </TableCell>
    </TableRow>
  );
};

export default TableCustomRow;
