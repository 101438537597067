import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  colors,
  makeStyles, TextField, Grid, Checkbox
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const Filters = ({ className, stateFilter, ...rest }) => {
  const classes = useStyles();

  const [pageListState, setPageListState] = useState({
    pages: [{value: 'all', label: 'All'}],
    posts: true,
    stories: true,
    reels: true
  });

  const [pageNamesFetchedState, setPageNamesFetchedState] = useState(false);

  const getPages = () => {
    if(!pageNamesFetchedState) {
      let pages = [{value: 'all', label: 'All'}];
      let baseUrl = 'https://api.combanalytics.com';
      if(process.env.NODE_ENV === 'development'){
        baseUrl = 'https://dev.combanalytics.com';
      }
      let path = window.location.pathname;
      let pathUrl = "/facebookPages";
      let platform = "Facebook";
      if (path.includes("facebook")) {
        pathUrl = '/facebookPages';
      } else if (path.includes("instagram")) {
        platform = "Instagram";
        pathUrl = '/instagramPages';
      } else if (path.includes("youtube")) {
        platform = "YouTube";
        pathUrl = '/youtubePages';
      }
      fetch(
        baseUrl + pathUrl + '?token=' + localStorage.getItem('token'),
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "userId": localStorage.getItem('user_id'),
            "site": platform
          })
        }
      )
        .then(res => res.json())
        .then(json => {
          if (json) {
            for (let i = 0; i < json.length; i++) {
              let tempObj = {value: json[i].site_page_id, label: json[i].name}
              pages.push(tempObj);
            }
          } else {
            console.log("fail");
          }
          console.log("PAGES:");
          console.log(pages);
          console.log(json);
          let tempState = pageListState;
          tempState.pages = pages;
          setPageListState(tempState);
        });
      setPageNamesFetchedState(true);
    }
  }

  const handleChange = (event) => {
    setPageListState({
      ...pageListState,
      [event.target.name]: event.target.value
    });
    stateFilter(event.target.name, event.target.value);
  };

  const updatePostTypes = (event) => {
    setPageListState({
      ...pageListState,
      [event.target.name]: event.target.checked
    });
    stateFilter(event.target.name, event.target.checked);
  };

  getPages();

  return (
    <Card
      className={clsx(classes.root, className)}
      style={{padding: "12px", backgroundColor: "#F4F6F8", border: "none", boxShadow: "none"}}
      {...rest}
    >

      <form
        autoComplete="off"
        noValidate
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              label="PAGES"
              name="page"
              onChange={handleChange}
              required
              select
              SelectProps={{ native: true }}
              value={pageListState.platform}
              variant="outlined"
            >
              {pageListState.pages.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid style={{fontFamily: "roboto"}} item>
            POSTS
            <Checkbox
              checked={pageListState.posts}
              onChange={updatePostTypes}
              name="posts"
              label="POSTS"
            />
          </Grid>
          <Grid style={{fontFamily: "roboto"}} item>
            STORIES
            <Checkbox
              checked={pageListState.stories}
              onChange={updatePostTypes}
              name="stories"
              label="STORIES"
            />
          </Grid>
          <Grid style={{fontFamily: "roboto"}} item>
            REELS
            <Checkbox
              checked={pageListState.reels}
              onChange={updatePostTypes}
              name="reels"
              label="REELS"
            />
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

Filters.propTypes = {
  className: PropTypes.string
};

export default Filters;
