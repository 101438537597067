import React, {useState} from 'react';
import {
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Engagement from 'src/views/posts/PostsSummaryView/Engagement';
import Posts from 'src/views/posts/PostsSummaryView/Posts';
import Post from 'src/views/posts/PostsSummaryView/Post';
import Filters from 'src/views/posts/PostsSummaryView/Filters';
import Followers from 'src/views/posts/PostsSummaryView/Followers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PostsSummary = () => {

  const classes = useStyles();

  const postPageState = {
    page: 'all',
    posts: true,
    stories: true,
    reels: true,
  };

  const followers = {};

  const [postState, setPostState] = useState(postPageState);
  const [followersState, setfollowersState] = useState(followers);

  const getFollowers = (pageId) => {
    let tempfollowers = [];
    let tempDates = [];
    let min = 0;
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    fetch(
      baseUrl + '/followerCounts' + '?token=' + localStorage.getItem('token'),
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "userId": localStorage.getItem('user_id'),
        "site": "Facebook",
        "site_page_id": pageId
      })
    }
    )
    .then(res => res.json())
    .then(json => {
      if (json.data) {
        console.log(json.data);
        for(let i = 0; i < json.data.length; i++){
          tempfollowers.push(parseInt(json.data[i].follower_count));
          tempDates.push(json.data[i].date);
        }
        min = Math.min(...tempfollowers);
      } else {
        console.log("fail");
      }
      setfollowersState({'followers': tempfollowers, 'dates': tempDates, 'min': min});
    });
  }

  const callbackFunction = (stateKey, stateVal) => {
    setPostState({...postState, [stateKey]: stateVal});

    let newState = postState;
    newState[stateKey] = stateVal;

    let postTypes = [];
    if(newState.posts){
      postTypes.push('post');
    }
    if(newState.stories){
      postTypes.push('story');
    }
    if(newState.reels){
      postTypes.push('reel');
    }

    setPgFetchedState(false);

    if(stateKey === 'pages' && stateVal !== 'all'){
      getPosts(stateVal, postTypes);
    } else {
      getPosts('all', postTypes);
    }
  };

  const apiPosts = [];
  const [apiPostsState, setApiPostsState] = useState(apiPosts);

  const pgFetched = false;
  const [pgFetchedState, setPgFetchedState] = useState(pgFetched);

  const getPosts = (pageId, postTypes) => {
    if (pgFetchedState === false) {
      let baseUrl = 'https://api.combanalytics.com';
      if(process.env.NODE_ENV === 'development'){
        baseUrl = 'https://dev.combanalytics.com';
      }
      let body = {"user_id": localStorage.getItem('user_id'), "site": "Facebook"}
      if(pageId !== 'all'){
        body.site_page_id = pageId;
        console.log("confirmed")
      }
      body.post_types = postTypes;

      fetch(
        baseUrl + '/facebookPosts',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      )
        .then(res => res.json())
        .then(json => {
          if (json) {
            let items = [];
            for (let i = 0; i < json.length; i++) {
              let obj = json[i];
              let words = [];
              let word_count_json = JSON.parse(obj.word_count_json);

              if (word_count_json !== null) {
                for (let x in word_count_json) {
                  words.push({text: x, value: word_count_json[x]});
                }
              }

              localStorage.setItem('post_cloud_' + obj.site_post_id, JSON.stringify(words));

              items.push(<Post
                key={i}
                id={obj.site_post_id}
                icon={obj.site}
                image={obj.img_url}
                date={obj.site_created_at}
                message={obj.message}
                words={words}
                likes={obj.like_count}
                reactions={obj.reaction_count}
                state={postState}
              />);

            }
            setApiPostsState(items);
          } else {
            console.log("fail");
          }
        });
      setPgFetchedState(true);
    }
  }

  let postTypes = [];
  if(postState.posts){
    postTypes.push('post');
  }
  if(postState.stories){
    postTypes.push('story');
  }
  if(postState.reels){
    postTypes.push('reel');
  }

  getPosts(postState.page, postTypes);

  return (
    <Page
      className={classes.root}
      title="Posts"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          alignItems="flex-start"
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Filters stateFilter={callbackFunction} />
          </Grid>
          <Grid
            container
            xl={12}
            style={{padding: '12px'}}
            padding={12}
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Grid
                item
                lg={3}
                md={3}
                xl={3}
                xs={3}
              >
                <Posts />
                {apiPostsState.length === 0
                && <Card style={{fontFamily: "arial"}}><CardContent>No Posts in the last 7 days found for this account.  Please make sure your Social Media Accounts have been connected on the <a href="account">My Profile Page</a></CardContent></Card>
                }
              </Grid>
            </Grid>

            {apiPostsState}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PostsSummary;
