import React, {useEffect, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import {PaymentElement, useElements, useStripe, Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51NgQbLLPrXlrKzRcDmYRuKVzOKZlyl99ub6qgdblvjcEF5IDzdyGLps0xUYpSOXQxwfievxtWmANGOjnolnhbGqK00nko0SnFi');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Payment = () => {

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    let baseUrl = 'https://api.combanalytics.com';
    if(process.env.NODE_ENV === 'development'){
      baseUrl = 'https://dev.combanalytics.com';
    }
    fetch(
      baseUrl + '/stripe',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    .then(res => res.json())
    .then(json => {
      if(json.secret){
        setClientSecret(json.secret);
      }
    })

  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };


  const classes = useStyles();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Elements stripe={stripePromise} options={options}>
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >

            <form onSubmit={handleSubmit}>
              <PaymentElement />
              <button disabled={!stripe}>Submit</button>
            </form>

        </Grid>
      </Container>
    </Page>
    </Elements>
  );
};

export default Payment;
